import request from '@/utils/request'

// 游客-获取指定民宿旅馆的房间列表
export function Wx_GetHotelRoomList(query) {
  return request({
    url: 'HotelRoom/Wx_GetHotelRoomList',
    method: 'get',
    params: query,
  })
}

// 游客端-获取我的订单列表
export function Wx_GetMyPreHotelPage(query) {
  return request({
    url: 'PreHotel/Wx_GetMyPreHotelPage',
    method: 'get',
    params: query,
  })
}

// 获取活动信息列表
export function WeGetRealInfoPage(query) {
  return request({
    url: 'RealInfo/WeGetRealInfoPage',
    method: 'get',
    params: query,
  })
}
