<template>
  <div class="information">
    <van-sticky>
      <van-tabs
        v-model="status"
        @click="onClick"
        color="#1989fa"
        style="border: 10px solid rgb(247, 247, 247)"
      >
        <van-tab title="待支付" name="1"> </van-tab>
        <van-tab title="支付中" name="2"> </van-tab>
        <van-tab title="待确定" name="3"> </van-tab>
        <van-tab title="待入住" name="4"> </van-tab>
        <van-tab title="已入住" name="5"> </van-tab>
        <van-tab title="支付失败" name="6"> </van-tab>
        <van-tab title="退款中" name="7"> </van-tab>
        <van-tab title="已退订" name="8"> </van-tab>
        <van-tab title="退款失败" name="9"> </van-tab>
        <van-tab title="已取消" name="99"> </van-tab>
      </van-tabs>
      <!-- <van-search value="请选择筛查条件"
        @click="searchClick"
        shape="round"
        background="#ffffff"
        disabled>
      </van-search> -->
      <!-- <van-popup v-model="searchShow"
        position="top"
        round>
        <div>

          <van-field v-model="inTime"
            placeholder="请选择入住时间"
            readonly
            label="入住时间"
            @click="inTimeClick"
            name="inTime" />
          <van-calendar v-model="inTimeShow"
            type="range"
            @confirm="onInTime"
            color="#1989fa"
            :min-date="minDate"
            :max-date="maxDate" />
          <van-field v-model="leaveTime"
            placeholder="请选择退房时间"
            readonly
            label="退房时间"
            @click="leaveTimeClick"
            name="leaveTime" />
         <van-calendar v-model="leaveTimeShow"
            type="range"
            @confirm="onLeaveTime"
            :min-date="minDate"
            :max-date="maxDate"
            color="#1989fa" />
          <van-field v-model="filters.title"
            placeholder="请输入名称"
            label="名称"
            name="title" />
          <van-field v-model="filters.abiName"
            readonly
            label="职位"
            placeholder="请选择职位"
            @click="abilityListShow = true" />
          <van-popup v-model="abilityListShow"
            round
            position="bottom">
            <van-cascader v-model="cascaderValue"
              title="请选择职位"
              :options="AbilityList"
              @close="abilityListShow = false"
              @finish="onAbilityListFinish"
              :field-names="fieldNames" />
          </van-popup>
          <van-field v-model="filters.abiName"
            label="职位"
            placeholder="请选择职位"
            @click="nationPicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="nationPicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="nationList"
              value-key="Name"
              @confirm="nationQr"
              @cancel="nationPicker = false" />
          </van-popup>
          <van-field v-model="filters.eduDesc"
            label="学历"
            placeholder="请选择学历"
            @click="eduTypePicker = true"
            readonly="readonly"
            clickable />
          <van-popup v-model="eduTypePicker"
            position="bottom">
            <van-picker show-toolbar
              :columns="eduCodeList"
              value-key="Name"
              @confirm="eduTypeQr"
              @cancel="eduTypePicker = false" />
          </van-popup>
          <van-field v-model="filters.workAddr"
            placeholder="请输入工作地点"
            label="工作地点"
            name="workAddr" />
          <van-field v-model="filters.eName"
            placeholder="请输入招聘企业"
            label="招聘企业"
            name="eName" />
          <div class="filtersBox">
            <div class="labelClass"
              style="padding: 10px 6px;">
              <div>
                紧急程度
              </div>
            </div>
            <div style="padding: 10px 14px 6px 5px;">
              <van-radio-group v-model="filters.sex"
                @change="sexChange"
                direction="horizontal">
                <van-radio name="1"
                  class="radio">一般</van-radio>
                <van-radio name="2"
                  class="radio">紧急</van-radio>
                <van-radio name="3"
                  class="radio">非常紧急</van-radio>
              </van-radio-group>
            </div>
          </div>

          <div style="display: flex;margin-bottom: 10px;justify-content: space-around;">
            <button @click="resetSubmit"
              class="buttonStyle"
              style="background-color: #ff976a;">重 置</button>
            <button @click="filtersSubmit"
              class="buttonStyle">搜 索</button>
          </div>
        </div>
      </van-popup> -->
    </van-sticky>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      offset="10"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="center" v-for="item in list" :key="item.index">
        <div class="list">
          <div @click="preHotel(item)">
            <div class="title">{{ item.OrgName }}</div>
            <div class="flex van-multi-ellipsis--l2">
              <span>民宿旅馆: {{ item.HotelName }}</span>
            </div>
            <div class="flex van-multi-ellipsis--l2">
              <span>房间名称: {{ item.RoomTitle }}</span>
            </div>
            <div class="flex">
              <span>预订时间: {{ item.PreTime }}</span>
            </div>
            <div class="flex">
              <span>入住时间: {{ item.InDate }}</span>
            </div>
            <div class="flex">
              <span>退房时间: {{ item.LeaveDate }}</span>
            </div>
            <!-- <div class="flex">
              <span>旅客姓名: {{ item.Name }}</span>
              <span>房间数量: {{ item.RoomNum }}</span>
            </div> -->

            <!-- <div class="flex">
              <span>手机号: {{ item.Mobile }} </span>
            </div>

            <div class="flex">
              <span>身份证号: {{ item.IdCard }}</span>
            </div> -->
            <!-- <div class="flex">
            <span>入住日期: {{item.InDate}} </span>
            </div>
            <div class="flex">
              <span>退房日期: {{ item.LeaveDate }}</span>
            </div> -->
            <div class="flex">
              <span>预计到店时间: {{ item.ArriveTime }}</span>
            </div>

            <div class="Status" style="color: #4cbfec" v-if="item.Status == 1">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #e6776f" v-if="item.Status == 2">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #ff804d" v-if="item.Status == 3">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #fec900" v-if="item.Status == 4">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #02c08f" v-if="item.Status == 5">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #d61d10" v-if="item.Status == 6">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #ff804d" v-if="item.Status == 7">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #d61d10" v-if="item.Status == 8">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #d61d10" v-if="item.Status == 9">
              {{ item.StatusDesc }}
            </div>
            <div class="Status" style="color: #666" v-if="item.Status == 99">
              {{ item.StatusDesc }}
            </div>
          </div>
          <div
            style="
              display: inline-block;
              width: 100%;
              text-align: right;
              margin-bottom: 5px;
            "
            class="ContentB"
          >
            <span class="centertime" style="color: #333">
              <van-button
                type="warning"
                v-if="
                  item.Status == 1 ||
                  item.Status == 3 ||
                  item.Status == 4 ||
                  item.Status == 6
                "
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="inDateClick(item)"
                size="small"
                >取消入住</van-button
              >

              <van-button
                v-if="item.Status == 5"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="pingJia(item)"
                size="small"
                >评价</van-button
              >
              <!-- <van-button
                type="danger"
                v-if="item.Status == 1"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 15px;
                "
                @click="dangerClick(item)"
                size="small"
                >继续支付</van-button
              > -->
            </span>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { GetCodeEntrysList } from "@/api/wangge";
import {
  HLeaveRooOfAll,
  WxCancelPreHotel,
  Wx_RepayPreHotelPay,
} from "@/api/shop";
import { Wx_GetMyPreHotelPage } from "@/api/Hotel";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      status: 1, //tab栏
      searchShow: false, //筛查添加弹窗
      inTimeShow: false, //入住时间弹窗
      leaveTimeShow: false, //退房时间弹窗
      nationPicker: false, //民族弹窗
      inTime: "", //入住时间范围
      leaveTime: "", //退房时间范围
      eduCodeList: [], //民族列表
      shopTypePicker: false, //学历
      eduTypePicker: false, //学历弹窗
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2026, 0, 31),
      cascaderValue: "",
      filters: {
        status: 1,
        page: 1,
        limit: 10,
      }, //搜索表单
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      list: [],
      changeRoomOfAllShow: false,
      roomList: [],
      onlyChangeRoom: "",
      guestId: "",
      abilityListShow: false, //职位列表弹窗
      AbilityList: [], //职位列表
      fieldNames: {
        text: "Name",
        value: "Coding",
        children: "Children",
      },
    };
  },
  mounted() {
    // this.zidianlist();
    // this.filters.roomId = this.$route.params.roomId;
    // this.filters.hotelId = this.$route.params.hotelId;
    this.getList(undefined);
  },

  methods: {
    // 继续支付
    dangerClick: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "您确定要继续支付该订单吗?",
      })
        .then(() => {
          console.log("确认");
          Wx_RepayPreHotelPay({
            // HotelId: this.$route.params.hotelId,
            PreId: val.PreId,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                console.log(res.data);
                Toast.success("操作成功");
                // 跳转到 小程序 调用pay 方法
                if (window.__wxjs_environment === "miniprogram") {
                  window.wx.miniProgram.navigateTo({
                    url:
                      "../public/pay?nonceStr=" +
                      res.data.nonceStr +
                      "&preId=" +
                      res.data.preId +
                      "&prepayid=" +
                      res.data.prepayid +
                      "&sign=" +
                      res.data.sign +
                      "&timeStamp=" +
                      res.data.timeStamp,
                  });
                } else {
                  alert("请在小程序中打开");
                }
                // this.filters.page = 1;
                // this.list = [];
                // this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 取消入住
    inDateClick(row) {
      Dialog.confirm({
        title: "提示",
        message: "确定要取消入住吗",
      })
        .then(() => {
          console.log("确认");

          WxCancelPreHotel({
            PreId: row.PreId,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                this.filters.page = 1;
                this.list = [];
                Toast.success("操作成功");
                // this.$router.go(-1);
                this.getList(undefined);
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },

    // 选中职位
    onAbilityListFinish({ selectedOptions }) {
      this.abilityListShow = false;
      // this.filters.abiName = selectedOptions.map((option) => option.Name).join('/');
      this.filters.abiName = selectedOptions[selectedOptions.length - 1].Name;
      this.filters.abiCode = selectedOptions[selectedOptions.length - 1].value;
    },
    // tan点击切换事件
    onClick(e) {
      this.list = [];
      this.filters.status = e;
      this.filters.page = 1;
      this.getList();
    },
    onSearch(val) {
      this.filters.roomCode = val;
      this.list = {};
      this.getList(undefined);
    },
    onBlur(event) {
      this.filters.roomCode = event.target.value;
      this.list = {};
      this.getList(undefined);
    },
    // 字典查询
    zidianlist: function () {
      GetCodeEntrysList({ kind: "3" }).then((res) => {
        this.nationList = res.data.data;
      });
      GetCodeEntrysList({ kind: "5" }).then((res) => {
        this.eduCodeList = res.data.data;
      });
    },
    // 学历确认
    eduTypeQr(val) {
      this.filters.eduCode = val.Coding;
      this.filters.eduDesc = val.Name;
      this.eduTypePicker = false;
    },
    // 点击查看更多条件
    searchClick() {
      this.searchShow = true;
    },
    // 点击入住时间
    inTimeClick() {
      this.inTimeShow = true;
    },
    // 选中入住时间
    onInTime(event) {
      const [start, end] = event;
      this.inTimeShow = false;
      this.inTime = `${this.formatDate(start)}  ~  ${this.formatDate(end)}`;
      this.filters.startInTime = this.formatDate(start);
      this.filters.endInTime = this.formatDate(end);
    },
    // 点击退房时间
    leaveTimeClick() {
      this.leaveTimeShow = true;
    },
    // 选中退房时间
    onLeaveTime(event) {
      const [start, end] = event;
      this.leaveTimeShow = false;
      this.leaveTime = `${this.formatDate(start)}  ~  ${this.formatDate(end)}`;
      this.filters.startLeaveTime = this.formatDate(start);
      this.filters.endLeaveTime = this.formatDate(end);
    },
    //性别
    sexChange(val) {
      this.filters.sex = val;
    },
    // 退房
    leavedChange(val) {
      this.filters.leaved = val;
    },
    // 登记类型
    enrolCateChange(val) {
      this.filters.enrolCate = val;
    },
    // 民族确认
    nationQr(val) {
      this.filters.nationCode = val.Coding;
      this.filters.nationDesc = val.Name;
      this.nationPicker = false;
    },
    // 重置
    resetSubmit() {
      this.inTime = "";
      this.leaveTime = "";
      this.filters = {
        title: undefined,
        abiCode: undefined,
        abiName: undefined,
        workAddr: undefined,
        eduCode: undefined,
        urgency: undefined,
        eName: undefined,
        page: 1,
        limit: 10,
        page: 1,
        limit: 10,
      };
    },
    // 搜索
    filtersSubmit() {
      console.log(this.filters);
      this.filters.page = 1;
      this.list = [];
      this.searchShow = false;
      this.getList();
    },
    // 上拉加载更多
    onLoad() {
      this.filters.page = this.filters.page + 1;
      this.getList(undefined);
    },
    // 获取旅客列表
    getList(row) {
      this.filters.openID = getOpenId();
      Wx_GetMyPreHotelPage(this.filters).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          let rows = res.data.data; //请求返回当页的列表
          if (rows == null || rows.length === 0) {
            // 加载结束
            console.log("结束");
            this.finished = true;
            this.hidden = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= res.data.count) {
            this.finished = true;
            this.hidden = true;
          }
        }
        // if (res.data.code == 0) {

        //   if (res.data.count == 0) {
        //     this.finished = true;
        //     this.list = {};
        //   } else {
        //     this.list = res.data.data;
        //     this.total = res.data.count;
        //     this.finished = true;
        //   }
        // }
      });
    },
    // 详情
    preHotel(val) {
      var url = "/hotel/myCheckIn/cherkInXq/" + val.PreId;
      this.$router.push(url);
    },
    // 编辑
    updata(val) {
      var url =
        "/hotel/myHotelsList/myGuestList/addGuest/1/" +
        this.$route.params.hotelId +
        "/" +
        this.$route.params.roomId +
        "/" +
        val.GuestId;
      this.$router.push(url);
    },
    // 评价
    pingJia(val) {
      console.log(val);
      var url = "/hotel/pingJia/index/" + val.PreId;
      this.$router.push(url);
    },
    // 按房间换房
    changeRoomOfAll() {
      this.changeRoomOfAllShow = true;
      this.onlyChangeRoom = 2;
    },

    // 按房间退房
    leaveRooOfAll() {
      Dialog.confirm({
        title: "提示",
        message: "确定要退房吗",
      })
        .then(() => {
          console.log("确认");
          HLeaveRooOfAll({
            HotelId: this.$route.params.hotelId,
            RoomId: this.$route.params.roomId,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.$router.go(-1);
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },

    // 时间格式化
    formatDate(date) {
      var date = new Date(date);
      let year = date.getFullYear(); //年
      let month = date.getMonth() + 1; //月
      month = month.toString().padStart(2, "0"); // 当小于10时，显示为01.02.03
      let day = date.getDate(); //日
      day = day.toString().padStart(2, "0"); // 当小于10时，显示为01.02.03
      return year + "-" + month + "-" + day;
    },
  },
};
</script>
<style scoped>
.labelClass {
  font-size: 14px;
  font-weight: 500;
  width: 86px;
  color: #646566 !important;
}
.radio {
  font-size: 14px;
  color: #646566 !important;
}
.buttonStyle {
  width: 45%;
  border-radius: 8px;
  background: #1989fa;
  color: white;
  height: 35px;
  line-height: 35px;
  border: 0px solid;
}
.filtersBox {
  display: flex;
  border-bottom: 1px solid #ebedf085;
  margin: 0px 10px;
}
.list {
  position: relative;
  width: 96%;
  margin: auto;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
  padding-bottom: 10px;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 5px;
  justify-content: space-between;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 6px;
  right: 6px;
  /* background: #4b8cff; */
  font-size: 16px;
  padding: 5px 0px;
  color: #fff;
  width: 85px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.tsrq {
  font-size: 16px;
  margin: 10px 25px 10px 10px;
}
.tsrq span {
  border-left-color: #fff;
  border-left-width: 15px;
  border-left-style: solid;
  padding-left: 5px;
  line-height: 15px;
  display: inline-block;
  margin-right: 10px;
}
.box {
  width: 43%;
  border-radius: 5px;
  float: left;
  margin: 1%;
  background: #3e83e9;
  min-height: 80px;
  padding: 8px;
  color: white;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.box2 {
  background: #11e67b;
}
.box3 {
  background: #8a8a8a;
}
.ContentB {
  display: inline-block;
  width: 98%;
  text-align: right;
  margin-bottom: 10px;
}
.listButton {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 0;
  background-color: white;
}
</style>